@import "./assets/index.scss";

/*RESETS***************************************/
body {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  text-decoration: none;
  font-family: $fontRoboto;
  font-size: 62.5%;
  margin: 0%;
  padding: 0%;
}
/**********************************************/

.app {
  &__whatsapp {
    z-index: 999;
    position: fixed;
    right: 5vw;
    bottom: 10vh;
    &__icon {
      width: 60px;
    }
  }
}
