@import "../../../../assets/index.scss";

.services {
  background-color: whitesmoke;
  opacity: 1;
  padding: 40px 0px;
  &__content {
    &__row1 {
      &__title {
        text-align: center;
        color: $darkBlue;
        font-weight: 600;
        font-size: 1.5rem;
      }
      &__list {
        padding-left: 20px;
        list-style-type: none;
        font-size: 1.2rem;
        line-height: 40px;
        &__text {
          color: $darkBlue;
          font-weight: 500;
        }
        &__text::before {
          content: ">";
          margin-right: 10px;
          color: $blueLight;
          font-weight: 400;
        }
      }
    }
    &__row2 {
      &__title {
        text-align: center;
        color: $darkBlue;
        font-weight: 600;
        font-size: 1.5rem;
      }
      &__list--special {
        padding-left: 0px;
        font-size: 1.2rem;
        line-height: 40px;
        text-align: center;
        list-style-image: url("../../../../assets/imgs/icons/check.svg");
        list-style-position: inside;
        &__text {
          color: $darkBlue;
          font-weight: 500;
        }
        &__text::marker {
          font-size: 2rem;
        }
      }
    }
  }
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .services {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    &__content {
      display: flex;
      align-items: self-start;
      justify-content: space-around;
      width: 60%;
      &__row1 {
        &__title {
          font-size: 2rem;
          margin-bottom: 30px;
        }
        &__list {
          padding-left: 0px;
        }
      }
      &__row2 {
        &__title {
          font-size: 2rem;
          margin-bottom: 30px;
        }
      }
    }
  }
}
