@import "../../../../../assets/index.scss";

.card {
  border: none;
  padding: 15px 15px;
  box-shadow: 0px 4px 8px silver;
  &__img {
    height: 150px;
  }
  &__title {
    text-align: center;
    color: $darkBlue;
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  &__description {
    color: $darkBlue;
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
  }
  &__link {
    text-decoration: none;
    text-align: center;
    color: $blueLight;
    font-size: 1rem;
    font-weight: 600;
  }
}

.card:hover {
  box-shadow: 5px 5px 5px rgba(silver, 0.5), -5px -5px 5px rgba(silver, 0.5);
  transition: 0.1s;
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .card {
    width: 350px;
  }
}
