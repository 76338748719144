@import "../../../../assets/index.scss";

.carouselMaintenanceTimeline {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 300px;

    &__title {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      h6 {
        padding: 0px;
        margin: 0px;
        font-size: 1.5rem;
        color: $darkBlue;
        font-family: $fontRoboto;
      }
    }

    &__slider {
      &__container {
        &__imgs {
          &__img {
            width: 300px;
            height: 300px;
            background-color: transparent;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}

/* DESKTOP························································································································ */
@media (min-width: 1024px) {
  .carouselMaintenanceTimeline {
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
      height: 300px;
      width: 300px;
      display: flex;
      justify-content: center;
      &__slider {
        &__container {
          &__imgs {
            &__img {
              height: 300px;
              width: 300px;
            }
          }
        }
      }
    }
  }
}
