.carouselPresentation {
  &__body {
    &__container {
      overflow: hidden;
      &__img {
        width: 100%;
        height: 500px;
      }
      &__texts {
        position: absolute;
        top: 25%;
      }
      &__texts h5 {
        font-size: 2rem;
        font-weight: 600;
        text-shadow: 1px 1px 2px black;
        text-align: center;
      }
      &__texts p {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3rem;
        text-shadow: 1px 1px 2px black;
      }
      &__button:hover {
        background-color: transparent;
        border: 1px solid black;
      }
      &__button:hover a {
        color: black !important;
      }
    }
  }
}

/* DESKTOP························································································································ */
@media (min-width: 1024px) {
  .carouselPresentation {
    &__body {
      &__container {
        &__img {
          height: 1000px;
        }
        &__texts {
          position: absolute;
          top: 35%;
        }
        &__texts h5 {
          font-size: 4.5rem;
        }
        &__texts p {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
    }
  }
}
