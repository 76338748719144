.content {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0px 10px;
  &__container {
    &__section1 {
      display: flex;
      justify-content: center;
      column-gap: 30px;
      margin-top: 20px;
      &__imgFacebook {
        width: 10px;
      }
      &__imgWhatsapp {
        width: 17px;
      }
    }
    &__section2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      &__title {
        font-size: 1.2rem;
      }
      &__options {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        padding: 0px 15px;
        &__text {
          font-size: 0.9rem;
          font-weight: 300;
          margin: 0;
        }
      }
    }
    &__section3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      &__title {
        font-size: 1.2rem;
        margin: 0px;
      }
      &__text {
        margin: 10px 0px;
        font-size: 0.9rem;
        &__icon {
          width: 12px;
        }
      }
      &__numbers {
        &__text {
          font-size: 0.9rem;
          margin: 0;
          display: block;
          color: white;
          text-decoration: none;
          &__icon {
            width: 13px;
          }
        }
      }
      &__mail {
        color: white;
        text-decoration: none;
        margin-top: 10px;
        font-size: 0.9rem;
        &__icon {
          width: 15px;
        }
      }
    }
    &__section4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      &__logo {
        width: 300px;
      }
      &__text {
        margin-top: 15px;
        font-size: 0.9rem;
        font-weight: 300;
        text-align: center;
      }
    }
  }
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .content {
    flex-direction: row;
    justify-content: center;
    &__container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: self-start;
      width: 60%;
      &__section1 {
        width: 10%;
        &__imgFacebook {
          width: 12px;
        }
        &__imgWhatsapp {
          width: 20px;
        }
      }
      &__section2 {
        width: 30%;
        align-items: flex-start;
        padding: 10px;
        &__title {
          font-size: 1.5rem;
        }
        &__options {
          align-items: flex-start;
          &__text {
            font-size: 1rem;
          }
        }
      }
      &__section3 {
        width: 30%;
        align-items: flex-start;
        padding: 10px;
        &__text {
          font-size: 1rem;
          &__icon {
            width: 14px;
          }
        }
        &__title {
          font-size: 1.5rem;
        }
        &__numbers {
          &__text {
            font-size: 1rem;
            &__icon {
              width: 15px;
            }
          }
        }
        &__mail {
          font-size: 1rem;
        }
      }
      &__section4 {
        width: 30%;
        &__logo {
          width: 100%;
        }
        &__text {
          font-size: 1rem;
        }
      }
    }
  }
}
