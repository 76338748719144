@import "../../../assets/index.scss";

.buildingMaintenanceAndRestorations {
  margin: 0px;
  padding: 0px;
  &__bannerContainer {
    &__banner {
      width: 100%;
    }
  }
  &__promoTitle {
    margin-top: 50px;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    font-family: $fontRoboto;
    color: $blueLight;
    padding: 5px;
    border-radius: 5%;
    span {
      color: $red;
    }
    span img {
      width: 70px;
      margin: 0px 10px;
    }
  }
}
.animate__animated.animate__zoomIn {
  --animate-duration: 4s;
  animation-iteration-count: infinite;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 2s;
  animation-iteration-count: infinite;
}

/* DESKTOP························································································································ */
@media (min-width: 1024px) {
  .buildingMaintenanceAndRestorations {
    &__bannerContainer {
      display: flex;
      justify-content: center;
      &__banner {
        width: 50%;
      }
    }
    &__promoTitle {
      span {
      }
      span img {
      }
    }
  }
}
