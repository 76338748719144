@import "../../assets/index.scss";

.headerAux {
  position: relative;
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &__title {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: 1px 1px 2px black;
    text-align: center;
  }
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .headerAux {
    height: 180px;
    &__title {
      font-size: 2.8rem;
    }
  }
}
