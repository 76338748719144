@import "../../../assets/index.scss";

.navbar {
  margin: 0;
  background-color: $blueLight;
  display: flex;
  justify-content: center;
  min-height: 50px;
  height: 80px;
  &__content {
    position: relative;
    color: wheat;
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__botton {
      position: absolute;
      left: 10px;
    }
    &__logo {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      &__img {
        width: 200px;
        height: auto;
      }
    }
    &__menu {
      width: 70%;
      display: none;
      &__links {
        display: flex;
        column-gap: 40px;
        &__icon {
          width: 70px;
        }
      }

      &__links a {
        color: wheat;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
      }
      &__links a:hover {
        color: $blueLightLight;
      }
    }
  }
}

/* TABLET························································································································ */
@media (min-width: 768px) {
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .navbar {
    margin: 0;
    background-color: $blueLight;
    display: flex;
    justify-content: center;
    min-height: 50px;
    height: 90px;
    &__content {
      color: wheat;
      width: 70%;
      margin: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__menu {
        &__links {
          &__button {
            background-color: $red;
          }
          &__button:hover {
            color: $blueLightLight;
          }
        }
      }
      &__botton {
        display: none;
      }
      &__logo {
        vertical-align: middle;
        &__img {
          width: 240px;
          height: auto;
        }
      }
      &__menu {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
