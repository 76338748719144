@import "../../../assets/index.scss";

.contacts {
  margin: 0;
  background-color: $red;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 3px;
  &__row1 {
    display: flex;
    justify-content: space-around;
    &__text {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 600;
      text-decoration: none;
      color: white;
      &__img {
        fill: white;
      }
    }
    &__text:hover {
      color: $blueLight;
    }
  }
  &__row2 {
    display: flex;
    justify-content: space-evenly;
    &__text {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 600;
      text-decoration: none;
      color: white;
    }
    &__text:hover {
      color: $blueLight;
    }
  }
}

/* DESKTOP (SMALL) ······················································································································· */
@media (min-width: 1024px) {
  .contacts {
    flex-direction: row;
    padding: 10px;
    // justify-content: center;
    &__row1 {
      padding-left: 20%;
      column-gap: 20px;
    }
    &__row2 {
      padding-left: 20px;
      column-gap: 350px;
    }
  }
}
