@import "../../../../assets/index.scss";

.contact {
  &__content {
    padding: 50px 20px;
    &__title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      color: $darkBlue;
    }
    &__text {
      text-align: center;
      font-size: 0.9rem;
      color: $grey;
      font-weight: 200;
    }
    &__text span {
      font-weight: 600;
    }
    &__row1 {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }
    &__row2 {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    &__row3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      row-gap: 20px;
    }
    input {
      padding: 8px;
      font-size: 0.8rem;
      border: none;
      background-color: whitesmoke;
    }
    input:focus {
      border-color: green !important;
    }
    textarea {
      padding: 8px;
      font-size: 0.8rem;
      border: none;
      background-color: whitesmoke;
    }
  }
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .contact {
    display: flex;
    justify-content: center;
    &__content {
      padding: 50px 0px;
      width: 40%;
      &__title {
        font-size: 2.3rem;
      }
      &__text {
        font-size: 1.1rem;
      }
      &__row1 {
        flex-direction: row;
        justify-content: space-between;
        row-gap: 0;
        column-gap: 0px;
      }
    }
    input {
      width: 30%;
    }
  }
}
