@import "../../../../assets/index.scss";

.carouselMaintenance {
  width: 90%;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    padding: 0px 5px;
    h5 {
      font-size: 1.5rem;
      text-align: center;
      font-family: $fontRoboto;
      color: $blue;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
      width: 70%;
      margin-top: 50px;
    }
  }
}

/* DESKTOP························································································································ */
@media (min-width: 1024px) {
  .carouselMaintenance {
    width: 55%;
    padding-bottom: 40px;

    &__content {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      hr {
        display: none;
      }
    }
  }
}
