.cards {
  opacity: 1;
  background-color: whitesmoke;
  margin: 30px 0px 0px;
  padding-bottom: 50px;
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}

/* DESKTOP ······················································································································· */
@media (min-width: 1024px) {
  .cards {
    display: flex;
    justify-content: center;
    margin: -50px 0 0px;
    &__container {
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
